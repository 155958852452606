@import "trix/dist/trix";

.trix-button-group.trix-button-group--file-tools {
  display:none;
}

trix-editor {
  border: 1px solid #bbb;
  border-radius: 0px;
  margin: 0;
  padding: .4em .6em;
  min-height: 5em;
  outline: none;
  background: white !important;
}
trix-editor ul { list-style-type: disc !important; margin-left: 1rem !important; }
trix-editor ol { list-style-type: decimal !important; margin-left: 1rem !important; }
trix-editor div {
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
