.dz-remove {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

audio::-webkit-media-controls-panel {
  background-color: white;
}

@media print {
  .unprintable {
    display: none;
  }

  #description {
    display: block !important;
  }
}
