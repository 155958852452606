@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "mapbox-gl/dist/mapbox-gl.css";
@import 'tippy.js/dist/tippy.css';

@import "./actiontext.css";
@import "./calendar.css";
@import "./resource.css";
@import "./ts.css";
@import "./videojs.css";

.navigation_toggle::-webkit-details-marker {
  display: none;
}

details summary {
  padding: 1em;
  list-style: none;
}

details[open] ~ #mobile-menu { display: block; }

@layer components {
 .disabled-btn {
  @apply !cursor-not-allowed opacity-50;
 }
}
