@import "tom-select/dist/css/tom-select.css";

.ts-wrapper {
  @apply w-full !ml-0;
}

.ts-control {
  @apply
    shadow-sm
    border-gray-300
    bg-white
    py-0
    px-0
    text-base;

    /* Add caret https://github.com/orchidjs/tom-select/discussions/384 */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
}

.ts-dropdown {
  @apply
    border
    border-solid
    border-t
    border-gray-300
    pl-0
    py-0
    text-base;
}

.ts-dropdown .create:hover, .ts-dropdown .option:hover {
  @apply bg-gray-100 text-black;
}

.ts-dropdown .active {
  @apply bg-gray-100 text-gray-900;
}

.ts-input.focus {
  @apply
    outline-none
    ring-2
    ring-blue;
}
